import React from "react";
// import BG from "../../images/NewImages/Background.svg";
import hqlogo from "../../images/Home/mainqubelogo.png"

function NewMission() {
    return (
        <>
            <section class="new-mission-showcase">
                {/* center on top*/}
                {/* <div className="d-flex flex-row justify-content-center align-items-center gap-5">
                    <div>
                        <h5 class="new-mission-text">
                            Our Mission
                        </h5>
                        <p className="new-mission-paragraph">
                            Deliver Performance Systems Using the Latest Internet to Fight Global Home Shortage.
                        </p>
                    </div>
                    <div>
                        <h5 class="new-mission-text">
                            Our Values
                        </h5>
                        <p className="new-mission-paragraph">
                            Love for God and Neighbour
                        </p>
                    </div>
                </div> */}
                <div class="new-mission-bottom d-flex justify-content-center align-items-center">
                    {/* left side */}
                    <div className="d-flex flex-column justify-content-between gap-3">
                        <div class="new-mission-content-container-left">
                            <h5 class="new-mission-left-title">
                                Our Mission
                            </h5>
                            <p className="new-mission-left-paragraph">
                                We are building a Built Environment Engine to disrupt worldwide home scarcities through regeneration and decentralization.
                            </p>
                        </div>
                        {/* <div class="new-mission-content-container-right">
                            <h5 class="new-mission-left-title">
                                Our Belief
                            </h5>
                            <p className="new-mission-left-paragraph">
                                A decentralised world will be the enabler for
                                humanity to climb from poverty traps of supply,
                                knowledge, and production scarcity.
                            </p>
                        </div> */}
                    </div>
                    {/* center side the logo image*/}
                    <div className="new-mission-center-img">
                        <img src={hqlogo} alt="HomeQube" />
                    </div>
                    {/* right side  */}
                    <div className="d-flex flex-column justify-content-between gap-3">
                        <div class="new-mission-content-container-left">
                            <h5 class="new-mission-right-title">
                                Our Values
                            </h5>
                            <p className="new-mission-right-paragraph">
                                Love for God and Neighbour
                            </p>
                        </div>
                        {/* <div class="new-mission-content-container-right">
                            <h5 class="new-mission-right-title">
                                Worldview
                            </h5>
                            <p className="new-mission-right-paragraph">
                                Facts are what is recorded in the blockchain;
                                Truth is what supports your Beliefs.
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewMission;
