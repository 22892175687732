import React, { useState } from "react";
import QubeBotIcon from "./QubeBotIcon";
import { MdOutlineClose } from "react-icons/md";

const AIChatBot = () => {
  const [collapsed, setCollapsed] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // Loading state

  const handleIframeLoad = () => {
    setIsLoading(false); // Stop loading once the iframe has loaded
  };


  return (
    <>
      {/* Icon Button */}
      {!collapsed && (
        <div
          title="QUBE AI Chatbot"
          onClick={() => setCollapsed(true)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
            fontSize: "2rem",
            background: "transparent",
            cursor: "pointer",
            borderRadius: "100%",
            color: "white",
            position: "fixed",
            bottom: "1rem",
            right: "0.5rem",
            zIndex: 1002,
            "@media screen and (max-width: 1390px)": {
              bottom: "3.8rem",
            },
            "@media screen and (max-width: 768px)": {
              bottom: "1rem",
            },
          }}
        >
          <QubeBotIcon />
        </div>
      )}

      {/* Iframe Container */}
      {collapsed && (
        <div
          style={{
            position: "fixed",
            bottom: "4rem",
            right: "1.5rem",
            width: "min(390px, 100vw)",
            height: "min(100vh, 600px)",
            zIndex: 1000,
            borderRadius: "1.5%",
            background: "black",
            ...(window.innerWidth <= 1390 && {
              width: "min(370px, 100vw)",
            }),
            ...(window.innerWidth <= 420 && {
              width: "min(370px, 100vw)",
            }),
          }}
        >
          {/* Close Button */}
          <div
            onClick={() => setCollapsed(false)}
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              background: "gray",
              color: "white",
              borderRadius: "50%", // Makes the button a perfect circle
              cursor: "pointer",
              width: "2rem", // Equal width and height for the circle
              height: "2rem",
              display: "flex", // Center the close icon inside the circle
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              fontSize: "1rem",
              ...(window.innerWidth <= 420 && {
                right: "1.5rem",
              }),
            }}
          >
            <MdOutlineClose />
          </div>

          {/* Loading Indicator */}
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                zIndex: 1002,
              }}
            >
              QubeAI is Loading...
            </div>
          )}


          {/* Iframe */}
          <iframe
            title="QUBE AI Chatbot Iframe"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              borderRadius: "1.5%",
            }}
            src="https://hqwebapp-ai-rg-h2akcfb8a4a3faap.eastus-01.azurewebsites.net/"
            onLoad={handleIframeLoad}
          ></iframe>
        </div>
      )}
    </>
  );
};

export default AIChatBot;
